var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.list),function(passage,pIndex){return _c('div',{key:pIndex},[_c('hr'),_c('h4',[_vm._v(" "+_vm._s(passage.name)+": "),_c('span',[_c('span',{staticStyle:{"color":"#ff8920"}},[_vm._v(_vm._s(passage.correctCount))]),_vm._v("/"+_vm._s(passage.exam_questions.length))])]),_vm._l((Math.ceil(passage.exam_questions.length / _vm.lineCount)),function(i){return _c('div',{key:i,staticClass:"answer-table"},[_c('table',{staticClass:"table table-bordered"},[_vm._m(0,true),_c('tbody',_vm._l((passage.exam_questions),function(question,index){return _c('tr',{directives:[{name:"show",rawName:"v-show",value:((i - 1) * _vm.lineCount <= index && index < i * _vm.lineCount),expression:"(i - 1) * lineCount <= index && index < i * lineCount"}],key:question.order},[_c('td',{staticClass:"green-td"},[_vm._v(_vm._s(question.order))]),_c('td',[_c('router-link',{attrs:{"to":{
                  name: 'ACTExplanations',
                  params: {
                    examId: _vm.examId,
                    userExamId: _vm.userExamId
                  },
                  query: {
                    sectionOrder: pIndex + 1,
                    questionOrder: index + 1
                  }
                }}},[_c('div',[(question.userAnswer)?[(question.userAnswer.answer[0])?[_c('b',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAnswer),expression:"showAnswer"}],class:question.userAnswer.is_correct === 1
                            ? 'text-success'
                            : 'text-danger'},[_vm._v(" "+_vm._s(question.userAnswer.answer[0] ? _vm.getLetter( question.order, question.userAnswer.answer[0] ) : "-")+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showAnswer),expression:"!showAnswer"}]},[(question.userAnswer.is_correct === 1)?_c('div',{staticClass:"text-success"},[_c('i',{staticClass:"fa fa-check"})]):_c('div',{staticClass:"text-danger"},[_c('i',{staticClass:"fa fa-times"})])])]:_c('b',{staticStyle:{"color":"#ccc"}},[_vm._v(" / ")])]:_c('b',{staticStyle:{"color":"#ccc"}},[_vm._v(" / ")])],2)])],1),_c('td',[_c('router-link',{attrs:{"to":{
                  name: 'ACTExplanations',
                  params: {
                    examId: _vm.examId,
                    userExamId: _vm.userExamId
                  },
                  query: {
                    sectionOrder: pIndex + 1,
                    questionOrder: index + 1
                  }
                }}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAnswer),expression:"showAnswer"}],staticClass:"text-success"},_vm._l((question.question.answers),function(answer){return _c('b',{key:answer.id},[_vm._v(" "+_vm._s(answer && answer.answer && answer.answer[0] ? _vm.getLetter(question.order, answer.answer[0]) : "?")+" ")])}),0),_c('b',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showAnswer),expression:"!showAnswer"}]},[_vm._v(" * ")])])],1),_c('td',[_c('router-link',{attrs:{"to":{
                  name: 'ACTExplanations',
                  params: {
                    examId: _vm.examId,
                    userExamId: _vm.userExamId
                  },
                  query: {
                    sectionOrder: pIndex + 1,
                    questionOrder: index + 1
                  }
                }}},[(question.userAnswer)?_c('span',{staticClass:"text-info"},[_vm._v(" "+_vm._s(question.userAnswer.metadata ? _vm.showTotalTime(question.userAnswer.metadata.activities) : "")+" ")]):_vm._e()])],1)])}),0)])])})],2)}),0)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',{staticClass:"rnum_1"},[_c('th',{staticClass:"green-td"},[_vm._v("#")]),_c('th',{staticClass:"green-td"},[_vm._v("Your Answer")]),_c('th',{staticClass:"green-td"},[_vm._v("Correct Answer")]),_c('th',{staticClass:"green-td"},[_vm._v("Answer Time")])])])
}]

export { render, staticRenderFns }